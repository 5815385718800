@import '../../variables.scss';

.navbarContainer{
    @media screen and (max-width :600px) {
        display: none;
    }
    justify-content: center;
    display: flex;
    z-index: 2;
    position: fixed;
    bottom: 1rem;

}
.navbar{
    background-color: $pure-white-90perc;
    display: flex;
    justify-content: center;
    column-gap: 2rem;
    padding-inline: 2rem;
    border-radius: 50px;
}
.itemNavbar{
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10rem;
}
.itemNavbar:hover{
    transform: scale(0.90);
    transition: ease-in-out 0.2s;
    filter: opacity(0.5);
}

.iconNavbar{
    height: 2rem;
    width: auto;
    border-radius: 2rem;
    overflow: visible;
    padding: 1rem;
}
