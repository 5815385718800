@import '../../../variables.scss';

.sectionTitle{
    font-family: $main-font;
    font-weight: 900;
    font-size: 5rem;
    @media screen and (max-width:1024px) {
        text-align: center;
        font-size: 3rem;
    }

}
