@import '../../../variables.scss';
.categorySkillContainer{
    background-color: $white;
    color: ùblack;
    width:25rem;
    display: flex;
    flex-direction: column;
    box-shadow:  -15px -15px  1px $black;
    border: 3px solid $black;
}
.categoryTitle{
    text-align: center;
    font-size: 1.7rem;
    font-family: $main-font;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    padding:2rem;
    border-bottom: 3px solid $black;
    
    
    
}
.allSkillsContainer{
    align-items: baseline;
    margin: 2rem;
}