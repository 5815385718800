@import '../../../variables.scss';

.skillContainer{
    margin: 0.5rem;
    font-family: $main-font;
    display: flex;
    flex-direction: column;
}

.skillName{
    display: flex;
    align-items: center;
    gap: 1rem;

}
.skillName::before{
    background-image:  url('../../../assets/icons/sparkle.png');
    background-size: 1rem 1rem ;
    display: inline-block;
    width: 1rem; 
    height: 1rem;
    content:"";
}
.skillLevel{
    font-weight: 200;
    font-style: italic;
}