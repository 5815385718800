.associatif{
    padding: 3rem;
    display: flex;
    flex-direction: column;
    @media screen and (max-width :1024px) {
        height: auto;
        align-items: center;
        padding:2rem;
    }
    row-gap: 3rem;
}