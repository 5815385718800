@import '../../../variables.scss';

.experienceAssociatifContainer{
    display: flex;
    font-family: $main-font;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 100vh;


}
.id{
    font-size: 5rem;
    font-weight: 800;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.name{
    font-size: 4rem;
    font-weight: 800;
    display: flex;
    align-items: center;
    gap: 1rem;
    @media screen and (max-width :600px) {
        font-size: 3rem;
    }
}
.name::before{
    background-image: url('../../../assets/icons/sparkle_gold.png');
    background-size: 4rem 4rem ;
    height: 4rem;
    width: 4rem;
    display: inline-block;
    content: "";
    
}

.keywordsContainer{
    display: flex;
    gap: 2rem;
    align-items: center;
    margin : 0.5rem;
    @media screen and (max-width :1024px) {
        gap: 1rem;
        justify-content: center;
    }



}

.keywordItem{
    display: flex;
    height: 2rem;
    background-color: $white;
    border: 1px solid $black;
    border-radius: 1rem;
    align-items: center;
    padding-inline: 0.5rem;
    box-shadow: 3px 3px 1px $black;

    font-weight: 600;
    @media screen and (max-width :1024px) {
        height: auto;
        padding-inline:0.75rem;
        padding-block:0.375rem;
        text-align: center;
    }
}

.keywordItem:hover{
    box-shadow: 2px 2px 1px $black;
    filter: brightness(0.9);
    cursor:default;
}

.description{
    font-size: 1.25rem;
    margin-inline: 10rem;
    margin-block:3rem;
    overflow-wrap: break-word;
    @media screen and (max-width :1024px) {
        margin-inline: 1rem ;
        text-wrap: pretty;
    }
}

.oppositeAlign{
    justify-content: end;
    
    
}
.oppositeAlignKeywords{
    justify-content: end;
    @media screen and (max-width :1024px) {
        justify-content: center;
    }
}

.imagesAssoContainer{
 

    display: flex;
    justify-content: center;
    gap: 5rem;
    flex-wrap: wrap;

}

.imageAssoItem{
    height: 20rem;
    border: 3px solid $black;
    border-radius: 7px;
    @media screen and (max-width:600px) {
        height: 13rem;
    }
}

.imageAssoItem:hover{
    transform: scale(1.005);
    transition: ease .2s;
    box-shadow: 0px 30px 40px -25px rgba(0, 0, 0, 1);

}