@import '../../variables.scss';

.parcoursPro{
    padding: 3rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    @media screen and (max-width :1024px) {
        height: auto;
        align-items: center; 
        padding:2rem;
    }
}
.expProContainer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-family: $main-font;
    @media screen and (max-width :1024px) {
        flex-direction: column;
        align-items: center;
    }
}
.workAssetContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20rem;
}
.workAsset{
    height: 100%;
}