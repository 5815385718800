@import '../../variables.scss';
.accueil{
    height: 100vh;
    display: flex;
    align-items: center;
    width: 100%;
    @media screen and (max-width :1024px) {
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    
}

.image-container{
    width: 30%;
    display: flex;
    padding-left: 5rem;
    @media screen and (max-width :1024px) {
        padding: 0;
    }
}
.imgSizeContainer{
    width: 80%;
    @media screen and (max-width :600px) {
        width: 100%;
    }
}
.rounded-image{
    width: 100%;

}
.intitule{
    font-family: $main-font;
    font-optical-sizing: auto;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    padding-right: 5rem;
    @media screen and (max-width :1024px) {
        padding: 0;
    }
}
@keyframes typingFull {
    from { width: 0 }
    to { width: 50% }
  }
@keyframes typingPhone{
    from { width: 0 }
    to { width: 100% }
}
  
  /* The typewriter cursor effect */
@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: $orange; }
  }
.nom{
    display: inline-block;
    font-size: 3rem;
    text-align: center;
    font-weight: 900;
    margin: 0;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .4em solid $orange; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 1 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
    typingFull 1.5s steps(20, end),
      blink-caret .75s step-end infinite;
    width: fit-content;

    @media screen and (max-width :1024px) {
        align-items: center;
        animation: 
        typingPhone 1.5s steps(20, end),
        blink-caret .75s step-end infinite;
        font-size: 2rem;
     
    }
    
}

.poste{
    display: flex;
    
    gap: 1rem;
    border-block: 3px solid $black;
    padding :0.3rem;
    @media screen and (max-width :1024px) {
        flex-direction: column;
        align-items: center;
    }
}
.posteSpan{
    font-size: 2rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-align: center;
}
.posteSpan:not(:first-child)::before {
    content: "\00B7 \00a0 \00a0 ";
    @media screen and (max-width :1024px) {
        content:""
    }
}




