@import '../../variables.scss';

.footerContent{
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $taupe;
    border: none;

}

.iconeReseau{
    height: 2rem;
    padding: 1rem;
    
    
}
.lienReseau{
    :hover{
        filter: brightness(0.85);
    }
}
.copyright{
    font-family: $main-font;
    margin-top: 1rem;

}
