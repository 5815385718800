@import url('https://fonts.googleapis.com/css2?family=Afacad+Flux:wght@100..1000&display=swap');

/* Couleurs */
$cream:#ede7db;
$white:white;
$black:black;
$orange:#ffa500;
$blue:#2a2d9b;
$taupe:#bdb6a6;

/* Police */
$main-font: "Afacad Flux", sans-serif;

/* Transparents*/
$pure-white-0perc:rgba(255, 255, 255, 0);
$pure-white-30perc:rgba(255, 255, 255, .2);
$pure-white-90perc:rgba(255, 255, 255, 0.9);
$pure-white-100perc:rgba(255, 255, 255, 1);

$black-30perc:rgb(0, 0, 0,0.3);
