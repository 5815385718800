@import '../../../variables.scss';

.experienceContainer{
    padding: 2rem;
    flex: 1;
    @media screen and (max-width :1024px) {
        width: 70%;
    }
    display: flex;
    flex-direction: column;
}
.dateExpPro{
    font-weight: 600;
    font-size: 1.5rem;

}

.nameExpPro{
    font-weight: 600;
    color:$blue;
    font-size: 1.5rem;
    width: 100%;

}
.keywordsContainerExpPro{
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: baseline;
    margin : 1rem;
    @media screen and (max-width :1024px) {
        justify-content: center;
    }
    @media screen and (max-width :600px) {
        flex-direction: column;
        height: auto;
        gap: 1rem;
        align-items: baseline;
        margin: 0;
    }
   



}

.keywordItemExpPro{
    display: flex;
    background-color: $white;
    border: 1px solid $black;
    border-radius: 1rem;
    align-items: center;
    padding-inline: 0.5rem;
    box-shadow: 3px 3px 1px $black;
    height : 100%;
    font-weight: 600;
    font-size: 0.9rem;
    @media screen and (max-width :1024px) {
        height: auto;
        padding-inline:0.75rem;
        padding-block:0.375rem;
        text-align: center;
    }
}

.keywordItemExpPro:hover{
    box-shadow: 2px 2px 1px $black;
    filter: brightness(0.9);
    cursor:default;
}
.descriptionExpPro{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    font-size: 1.20em;
}