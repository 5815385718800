@import '../../variables.scss';

.presentation{
    min-height: 100vh;
    padding: 3rem;
    font-family: $main-font;
    font-weight: 400;
    font-size:1.25rem
}
.presentationContent{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;


}
.volleyAsset{
    width: 25%;
    @media screen and (max-width :600px) {
        width: 60%;
    }
}
.diplomeContainer{
    display: flex;
    align-items: center;
}
.iconDiplome{
    height: 3rem;
    margin-inline: 3rem;

}